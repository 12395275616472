/* Global Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "VAZIR";
  direction: rtl;
  scroll-behavior: smooth;
}

/* Font Face */
@font-face {
  font-family: "VAZIR";
  src: url("../assets/font/vazir-font-v29.1.0/Vazir-Medium.ttf");
}

/* HTML & Body Styles */
html, body, #root, .App {
  width: 100%;
  height: 100%;
  overflow-x: hidden; 
  background: #478CCF;
  background-image: url('../assets/images/blob-scene-haikei (1).svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
}

/* Full viewport height for body and html */
body, html {
  height: 100vh; /* Full viewport height */
}
